import api from '../../utils/api'

export async function open_msg (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/inquiries/messages/${payload}/open`)
}

// 会话列表点击掉到会话窗口申请online_id
export async function get_id (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         id:1222
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/sdk/users/${payload}`)
}

// 触发下步操作
export async function to_sent_msg (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         id:1222
  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/im/actions`,payload)
}
