<template>
  <!-- 倒数回到首页 -->
  <div class="back_out">
    <div class="back_inner">
        <van-count-down :time="time" format="ss" @finish="count_change" />秒后返回首页
    </div>
  </div>
  
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    time: {
      default: ()=>{},
      type: Number
    }
  },
  computed: {
  },
  methods: {
    count_change() {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.back_out {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back_inner {
  width: 126px;
  height: 24px;
  background: #EFEFEF;
  border-radius: 18px;  
  font-size: 12px;
  line-height: 24px;
  color: #808080;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

>>>.van-count-down {
  font-size: 12px;
  color: #808080;
}
</style>

