import api from '../../../utils/api'


export async function report_info () {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: [
  //         {
  //           name: '上呼吸道感染',
  //           inspection: ['血常规、CT胸片等4项检查','阿司匹林20ml*4 头孢拉定缓释片0.5mg'],
  //           url: '',
  //           time: 1623396251000,
  //         },
  //         {
  //           name: '高血压',
  //           inspection: ['血常规、CT胸片等4项检查','阿司匹林20ml*4 头孢拉定缓释片0.5mg'],
  //           url: '',
  //           time: 1623396251000,
  //         },
          
  //       ]
  //     })
  //   }, 3500)
  // })
  return api.get(`/api/v1/mp/robot/drug/history`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/recommend/${id}`, payload)
}





