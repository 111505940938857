<template>
<!-- 文本显示 -->
  <div :class="['chat-info2',{'chat-info11': is_right}]"> {{text}}</div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    text:{
      type: String,
      default: ''
    },
    is_right: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style scoped>
.chat-info2 {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
    padding: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #565656;
    border-radius: 0px 20px 20px 20px;
    &.chat-info11 {
      color: #fff;
      background: #0088FF;
      border-radius: 20px 0px 20px 20px;
    }
  }


</style>

