<template>
  <div @click="start_chat" class="start-bt">立即咨询</div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    online_doctor_id: {
      type: null,
      default: ''
    }
  },
  computed: {

  },
  methods: {
    start_chat() {
      this.$router.replace({
        path: '/online-inquiry-detail',
        query: {
          id: this.online_doctor_id
        }
      })
    }
  }
}
</script>
<style scoped>
.start-bt {
  height: 36px;
  background: #1890FF;
  border-radius: 18px;
  font-size: 14px;
  line-height: 36px;
  color: #FFFFFF;
  text-align: center;
  margin: 0 16px;
}
</style>
