import api from '../../../utils/api'


export async function recommend_info (payload) {
  //return api.post(`/api/v1/mp/triage/recommend/${payload}`)
  return api.get(`/api/v1/mp/robot/diagnosis/${payload}`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/recommend/${id}`, payload)
}

export async function get_order_id (payload) {
  return api.post(`/api/v1/mp/orders/`, payload)
}





