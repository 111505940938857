<template>
  <div class="report_card-out">
    <msg-loading :msg_loading="msg_loading" />
    <div class="front_text">{{data.list.length===0 ? '暂无就诊记录' : '以下是您的就诊记录'}}</div>
    <div class="report_card" v-if="data.list.length>0">
      <div class="report_card-box">
        <div class="report_card-items" v-for="(item, idx) in data.list" :key="idx">
          <div class="report_card-tip">{{format_date(item.time, 'MM月dd日')}}</div>
          <div class="report_card-title">{{item.name}}</div>
          <div class="report_card-discription">
            <div class="report_card-inner" v-for="(it, index) in item.inspection" :key="index">
              <div class="report_card-dot"></div>
              <div class="report_card-text">
                {{it}}
              </div>
            </div>
          </div>
          <div class="report_btn-out">
            <div class="report_card-btn" v-for="i in item.actions" :key="i.id" @click="detail_fn(i)">{{i.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { report_info } from "./service";
import { format_date } from '../../../utils/format'
export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      msg_loading: false,
      inner_data: null,
      names: [],
    };
  },
  computed: {},
  watch: {
    msg_loading(v) {
      if (!v) {
        this.$emit("loading_finished", "报告信息");
      }
    },
  },
  components: {},

  mounted() {
    this.get_data(this.data.id);
  },
  methods: {
    format_date,
    async get_data(id) {
      this.msg_loading = true;
      try {
        const { data } = await report_info(id);
        this.inner_data = data.reverse()
      } finally {
        this.msg_loading = false;
      }
    },
    detail_fn(item) {
      this.$emit("callback", item);
    },
  },
};
</script>

<style scoped>
.report_card-out {
  width: 100%;
  padding-top: 10px;
}

.report_card {
  width: 100%;
  margin: 16px 0;
  overflow-x: auto;

}

.report_card-box {
  display: -webkit-box;
}

.report_card-items {
  width: 252px;
  margin-right: 16px;
  background: #fff;
  padding: 0 11px 16px 11px;
  border-radius: 10px;
  color: #8c8c8c;
  font-size: 12px;
  line-height: 17px;
}

.report_card-tip {
  width: 72px;
  height: 23px;
  background: #008FFF;
  border-radius: 0px 0px 4px 4px;
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 16px;
}

.report_card-title {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #242938;
}

.report_btn-out {
  display: flex;
  justify-content: flex-end;
}

.report_card-btn {
  width: 59px;
  height: 24px;
  background: #008FFF;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 12px;
}

.report_card-inner {
  display: flex;
  align-items: flex-start;
}

.report_card-dot {
  width: 10px;
  height: 10px;
  background: #939393;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  margin-right: 5px;

}

.front_text {
  width: 161px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  line-height: 40px;
  color: #565656;
  text-align: center;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
}

.report_card-text {
  flex: 1;
}

</style>
