<template>
  <div class="info_card-out">
    <msg-loading :msg_loading="msg_loading" />
    <div class="info_card" v-if="!msg_loading && inner_data">
      <div class="info_card-head">
        <div class="info_card-img">
          <img :src="inner_data.avatar_url" alt="" width="40">
        </div>
        <div class="info_card-right">
          <div class="info_card-name">
            <span> {{inner_data.name}} </span>
            {{inner_data.position}}
          </div>
          <div class="info_card-name">{{inner_data.hospital}} {{inner_data.department}} {{inner_data.specialty}}</div>
        </div>
      </div>
      <div class="info_card-center">
        <div class="info_card-title">
          本次问诊费用需

          <span class="info_card-price">{{format_money(inner_data.price)}}元 </span>
        </div>
        <div class="info_card-title">包含24小时内咨询服务以及挂号费用</div>
      </div>

      <div class="info_card-bottom">
        <div class="info_card-btn" @click="confirm_fn">支付</div>
      </div>
    </div>
  </div>
</template>
<script>
import { doctor_info } from './service'
import {format_money} from '../../../utils/format'
export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      msg_loading: false,
      inner_data: null,
    };
  },
  computed: {},
  watch: {
    msg_loading(v) {
      if (!v) {
        this.$emit("loading_finished", "信息卡片", this.testFn);
      }
    },
  },
  components: {},

  mounted() {
    this.get_data(this.data.id)
  },
  methods: {
    format_money,
    async get_data(id){
      this.msg_loading = true
      try {
        const {data} = await doctor_info(id)
        this.inner_data = data
      }finally {
        this.msg_loading = false
      }
    },
    confirm_fn(){
      this.$router.push({
        path: '/online-inquiry-detail',
        query: {
          id: this.data.id
        }
      })
    },
  },
};
</script>

<style scoped>
.info_card-out {
  width: 100%;
  padding-top: 1px;
}

.info_card {
  background: #fff;
  padding: 11px 6px 0 6px;
  border-radius: 10px;
  color: #565656;
  font-size: 14px;
  line-height: 18px;
}

.info_card-head {
  padding: 0 10px 16px;
  display: flex;
  align-items: center;
}

.info_card-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info_card-right {
  color: #5F5F5F;
}

.info_card-name{
  & span {
    font-weight: 500;
  }
}

.info_card-center {
  padding: 0 10px 12px 10px;
}

.info_card-price {
  color: #0088FF;
  margin-left: 2px;
}

.info_card-bottom {
  padding: 10px;
  border-top: solid 1px rgba(112, 112, 112, 0.17);
}

.info_card-btn {
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background: #1890FF;
  border-radius: 16px;
}
</style>
