<template>
  <div class="wait">
    <span class="text">{{time_str}}</span>
  </div>
</template>
<script>
import {format_date} from '../../utils/format'

export default {
  data() {
    return {

    }
  },
  props: {
    data:{
      type: Object,
      default: ()=>{}
    },
  },
  computed: {
    time_str () {
      let str = format_date(new Date().getTime(), 'yyyy/MM/dd')
      let now_str = new Date(str).getTime()
      let time = this.data.time
      if (time >= now_str) {
        return format_date(time, 'HH:mm')
      } else if (now_str - time <= 24 * 60 * 60 * 1000) {
        return format_date(time, '昨天 HH:mm')
      } else if (now_str - time <= 7 * 24 * 60 * 60 * 1000) {
        let day = new Date(time).getDay()
        let day_arr = ['日', '一','二','三','四','五','六']
        return format_date(time, `星期${day_arr[day]} HH:mm`)
      }
      return format_date(time, 'yyyy-MM-dd HH:mm')
    }
  },
  methods: {

  }
}
</script>
<style scoped>
.text {
  height: 24px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 24px;
  color: #808080;
  text-align: center;
}
.wait {
  text-align: center;
}
</style>

