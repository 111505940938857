<template>
  <div>
    <img width="52" src="../../assets/img/online/robot.png"/>
     <MsgText :text="data.text" :is_right="is_right"/>
  </div>
</template>
<script>
import MsgText from './MsgText'
export default {
  data() {
    return {

    }
  },
  props: {
    data:{
      type: Object,
      default: ()=>{}
    },
    is_right: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MsgText
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style scoped>

</style>
