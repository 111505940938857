import api from '../../../utils/api'


export async function report_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: 
  //       {
  //         name: '盆腔超声',
  //         details: '超声所见：子宫后位，大小约50*41，轮郭光整，形态尚 正常，宫壁回声均匀，内膜8MM，居宫壁回声均匀，内膜8MM，居宫壁回声均匀，内膜8MM，居中，宫腔未见异常回声',
  //         url: '',
  //       },
  //     })
  //   }, 3500)
  // })
  return api.get(`/api/v1/mp/robot/examine/${payload}`)
}





