<template>
  <div class="tixing">
    <span class="text">
      {{data.text}}
    </span>
  </div>
</template>
<script>
import {format_date} from '../../utils/format'
export default {
  data() {
    return {

    }
  },
  props: {
    data:{
      type: Object,
      default: ()=>{}
    }
  },
  computed: {
  },
  methods: {
    format_date

  }
}
</script>
<style scoped>
  .tixing {
    text-align: center;
    font-size: 12px;
    color: #808080;
    & .time {
      padding-bottom: 5px;
    }
    & .text {
      height: 24px;
      background: #ccc;
      padding: 4px 10px;
      font-size: 12px;
      line-height: 24px;
      border-radius: 10px;
      color: #fff;
    }
  }
</style>
