<template>
  <!-- 图片显示 -->
  <img @click="img_prew()" style="max-width: 60%;max-height: 200px;margin-bottom: 10px;" :src="url"/>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
    }
  },
  props: {
    url:{
      type: String,
      default: ''
    }
  },
  computed: {
  },
  methods: {
    img_prew() {
      ImagePreview([this.url]);
    }
  }
}
</script>
<style scoped>
.chat-info {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
    padding: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #565656;
    border-radius: 0px 20px 20px 20px;
    & .chat-info {
      color: #fff;
      background: #0088FF;
      border-radius: 20px 0px 20px 20px;
    }
  }


</style>

