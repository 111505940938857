<template>
  <div class="report_card-out">
    <msg-loading :msg_loading="msg_loading" />
    <div class="report_card" v-if="!msg_loading && inner_data">
      <div class="report_card-box">

        <div class="report_card-items" v-for="(item, index) in inner_data.list" :key="index">
          <div class="report_card-tip">报告</div>
          <div class="report_card-title">{{item.name}}</div>
          <div class="report_card-discription">
            {{item.details}}
          </div>
          <div class="report_card-btn" @click="detail_fn(inner_data)">详情</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { report_info } from "./service";
export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      msg_loading: false,
      inner_data: null,
    };
  },
  computed: {},
  watch: {
    msg_loading(v) {
      if (!v) {
        this.$emit("loading_finished", "报告信息");
      }
    },
  },
  components: {},

  mounted() {
    this.get_data(this.data.id);
  },
  methods: {
    async get_data(id) {
      this.msg_loading = true;
      try {
        const { data } = await report_info(id);
        this.inner_data = data
      } finally {
        this.msg_loading = false;
      }
    },
    detail_fn(data) {
      //this.$emit("detail_click", inner_data);
      this.$router.push({path: '/health-record-exam', query: {id: data.id}})
    },
  },
};
</script>

<style scoped>
.report_card-out {
  width: 100%;
  padding-top: 1px;
}

.report_card {
  width: 100%;
  margin: 16px 0;
  overflow-x: auto;

}

.report_card-box {
  display: -webkit-box;
}

.report_card-items {
  width: 252px;
  margin-right: 16px;
  background: #fff;
  padding: 0 11px 16px 11px;
  border-radius: 10px;
  color: #8c8c8c;
  font-size: 12px;
  line-height: 17px;
}

.report_card-tip {
  width: 72px;
  height: 23px;
  background: #008FFF;
  border-radius: 0px 0px 4px 4px;
  font-size: 14px;
  line-height: 23px;
  color: #FFFFFF;
  text-align: center;
}

.report_card-title {
  padding: 16px 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #242938;
}

.report_card-discription {
  max-height: 51px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.report_card-btn {
  width: 59px;
  height: 24px;
  background: #008FFF;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 12px;
}
</style>
