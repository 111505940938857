<template>
  <div :class="['recommend_card-out',{'default-height': msg_loading}]">
    <msg-loading :msg_loading="msg_loading" />
    <div v-if="!data.other">
      <div class="chart_data_name" v-if="inner_data">
        以下项目是评估<span v-if="inner_data"> {{ inner_data.name }} </span
        >诊断是否成立所必须的。我推荐您直接在线预约，这将显著提升您就诊速度。
      </div>
      <div class="chart_data_name" v-else>
        当前疾病无推荐检验检查
      </div>
    </div>
    <div class="recommend_card" v-if="!msg_loading">
      <div class="fast_card" :class="data.other?'other':''" v-if="inner_data">
        <div class="fast_box-head">
          <div class="fast_card-title">
            {{ !data.other? inner_data.name+'基本检查、检验':'你也可以先做以下检查快速排筛' }}
          </div>
          <div class="fast_card-text" v-if="data.other">
            出检查报告后医生帮您排查,准确率高，免排队
          </div>
        </div>

        <div class="fast_card-center">
          <div
            class="fast_card-items"
            v-for="(item, idx) in lab_group"
            :key="idx"
            v-show="item.arr.length"
          >
            <img src="../../../assets/img/ai_doc-ill.png" alt="" width="16" />
            <div class="fast_card_itemOut">
              <div class="card_item-name">{{ item.name }}</div>
              <div class="card_item-content">
                <span v-for="(it, index) in item.arr" :key="index">
                  {{ it.standard_name }}
                </span>
              </div>
            </div>
          </div>

          <div class="speed_card-ad blue">
            <div>
              <div class="speed_card-ad-title">
                检查检验是疾病诊治的必要步骤
              </div>
              <div class="speed_card-ad-text">
                {{ text.ad_sub || "了解医生是如何确诊疾病的" }}
              </div>
            </div>
            <div class="speed_card-ad-btn" @click="ad_fn">了解更多</div>
          </div>
        </div>

        <div class="fast_card-bottom">
          <div class="fast_card-next" :class="data.other?'other':''" @click="next()">{{data.other?'预约检查':'我要预约检查'}}</div>
          <div class="doctor" v-if="!data.other" @click="callback">直接约医生</div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="show_situation"
      position="bottom"
      :close-on-click-overlay="false"
      round
      :style="{ minHeight: '23%' }"
    >
      <div class="situation_box">
        <div class="situation_title">
          {{ un_suitabel.name }}检查不适宜人群告知
        </div>
        <div class="situation_sub">请明确您不存在如下情况</div>
        <div class="situation_list" v-html="un_suitabel.des"></div>
        <div class="situation_bottom">
          <div class="situation_btn" @click="send_situation(true)">
            我不存在以上情况
          </div>
          <div class="situation_btn" @click="send_situation(false)">
            我有以上某项情况
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { recommend_info, get_order_id } from "./service";
import { mapMutations } from "vuex";
import { isEmptyObj } from '../../../utils/validate';
export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
    text: {
      default: () => {
        return {
          title: "推荐您尽快进行以下检验检查",
          sub: "检查检验是疾病诊治的必要信息",
          ad_title: "检查检验是疾病诊治的必要信息",
          ad_sub: "了解医生是如何确诊疾病的",
        };
      },
      type: Object,
    },
  },
  data() {
    return {
      msg_loading: false,
      lab_group: [],
      show_situation: false,
      un_suitabel: {
        name: "",
        des: "",
      },
      un_suitabel_idx: -1,
      inner_data: null,
    };
  },
  computed: {},
  watch: {
    msg_loading(v) {
      if (!v) {
        this.$emit("loading_finished", "推荐卡片", this.testFn);
      }
    },
  },
  components: {},

  mounted() {
    this.get_data(this.data.id);
  },
  methods: {
    ...mapMutations('exam', [
      'updateExam',
    ]),
    testFn() {
      console.log("回调");
    },
    async get_data(id) {
      this.msg_loading = true;
      try {
        const { data } = await recommend_info(id);
        this.set_data(data);
        this.inner_data = data;

      } finally {
        this.msg_loading = false;
      }
    },
    set_data(data) {
      if(isEmptyObj(data)){
        return
      }
      this.lab_group = [
        {
          name: "血",
          arr: [],
        },
        {
          name: "尿",
          arr: [],
        },
        {
          name: "粪便",
          arr: [],
        },
        {
          name: "其他",
          arr: [],
        },
        {
          name: "检查",
          arr: [],
        },
      ];
      let pause_arr_lab = data.laboratories.filter((item) => {
        return !item.optional;
      });

      let pause_arr_exam = data.examinations.filter((item) => {
        return !item.optional;
      });

      pause_arr_lab.forEach((item) => {
        switch (item.specimen) {
        case "血":
          this.lab_group[0].arr.push(item);
          break;
        case "尿":
          this.lab_group[1].arr.push(item);
          break;
        case "粪便":
          this.lab_group[2].arr.push(item);
          break;
        default:
          this.lab_group[3].arr.push(item);
        }
      });

      this.lab_group[4].arr = [...pause_arr_exam];
    },
    callback() {
      this.$emit("callback", { id: 16, event: 1,data:{id: this.inner_data.suggest_doctor_id}});
    },
    next() {
      this.$emit("next_click");
      this.situation_pop();
    },
    ad_fn() {
      this.$router.push({
        path: this.inner_data.ad_url
      })
      //this.$emit("ad_click");
    },
    situation_pop() {
      this.un_suitabel_idx++;
      this.un_suitabel = {
        name: "",
        des: "",
      }

      let arr = [
        ...this.inner_data.laboratories,
        ...this.inner_data.examinations,
      ].filter((item) => {
        return !item.optional;
      });
      for (let i = this.un_suitabel_idx; i < arr.length; i++) {
        let v = arr[i];
        if (v.un_suitable) {
          this.un_suitabel.name = v.standard_name;
          this.un_suitabel_idx = i;
          this.un_suitabel.des = v.un_suitable.split("；").join("<br>");
          break;
        }
      }
      this.$nextTick(() => {
        this.show_situation = !!this.un_suitabel.name;
        if (!this.show_situation) {
          this.update_to_page();
        }
      });
    },
    send_situation(v) {
      let lab = this.inner_data.laboratories.filter((item) => {
        return !item.optional;
      });
      let exam = this.inner_data.examinations.filter((item) => {
        return !item.optional;
      });

      if (this.un_suitabel_idx < lab.length) {
        lab[this.un_suitabel_idx].selected = v;
      } else {
        exam[this.un_suitabel_idx - lab.length].selected = v;
      }
      this.situation_pop();
    },
    async update_to_page() {
      let payload = {
        examine_ids: [],
        laboratory_ids: [],
      }
      let exam = this.inner_data.examinations
      let lab = this.inner_data.laboratories
      if(exam.length) {
        exam.forEach(item=>{
          if(payload.examine_ids.indexOf(item.standard_id) < 0) {
            payload.examine_ids.push(item.standard_id)
          }
        })
      }
      if(lab.length) {
        lab.forEach(item=>{
          if(payload.laboratory_ids.indexOf(item.standard_id) < 0) {
            payload.laboratory_ids.push(item.standard_id)
          }
        })
      }
      try {
        const {data} = await get_order_id(payload)
        this.$router.push({ path: "pre-order", query: { order_id: data.order_id }});
      } finally {
        console.log('done')
      }
    },
  },
};
</script>

<style scoped>
.chart_data_name {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  padding: 14px;
  font-size: 14px;
  line-height: 18px;
  color: #565656;
  border-radius: 0px 20px 20px 20px;
  margin-bottom: 20px;
  margin-right: 32px;
  & span {
    color: rgba(24, 144, 255, 1);
  }
}
.recommend_card-out {
  width: 100%;
  padding-top: 1px;
  &.default-height {
    min-height: 360px;
  }
}

.fast_card {
  padding: 20px 15px 0 15px;
  background: #ffffff;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  color: #222222;

  &.other {
    padding: 20px 0 0 10px;
    background: transparent;
  }
}

.details_pop-box {
  & .fast_card {
    padding: 20px 0 0 0;
    box-shadow: none;
  }

  & .fast_box-head,
  & .fast_card-center {
    border-bottom: none;
  }
}

.fast_box-head {
  padding-bottom: 14px;
  /* border-bottom: solid 1px rgba(112, 112, 112, 0.17); */
}

.fast_card-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  max-height: 38px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fast_card-text {
  margin-top: 3px;
  font-size: 12px;
  line-height: 19px;
  max-height: 19px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fast_card-center {
  padding: 18px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.17);
}

.fast_card-items {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: flex-start;
}

.fast_card_itemOut {
  flex: 1;
  margin-left: 5px;
}

.card_item-content {
  font-size: 12px;
  line-height: 19px;
  color: #0088ff;
  margin-top: 2px;
  font-weight: 400;
}

.fast_card-bottom {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.fast_card-next {
  min-width: 74px;
  height: 32px;
  background: #0088ff;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-right: 23px;
  border-radius: 16px;
  padding: 0 10px;

  &.other {
    width: 100%;
    margin-right: 0;
  }
}
.doctor {
  font-size: 14px;
  color: #0088ff;
}

.fast_card-save {
  height: 21px;
  background: #fefced;
  padding: 0 9px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 21px;
  color: #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;

  & img {
    margin-right: 5px;
  }
}

.speed_card-ad {
  background: #edfef6;
  padding: 14px 10px;
  margin-top: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #234f3f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.speed_card-ad.blue {
  background: #eaf7ff;

  & .speed_card-ad-btn {
    background: #0088ff;
  }
}

.speed_card-ad-text {
  font-size: 12px;
  line-height: 16px;
  color: #70988a;
}

.speed_card-ad-btn {
  width: 62px;
  height: 21px;
  background: #62d1a6;
  border-radius: 19px;
  font-size: 12px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.situation_box {
  padding: 24px;
}

.situation_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
}

.situation_sub {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #565656;
  margin-bottom: 14px;
margin-top: 4px;
}

.situation_list {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
  margin-bottom: 23px;
}

.situation_bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.situation_btn {
  width: calc((100% - 12px) / 2);
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 40px;
  color: #0088ff;
  text-align: center;
  border: 1px solid #eaeaea
}
</style>
