import api from '../../../utils/api'


export async function doctor_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '杨俊涛(get)',
  //         position: '主任医生(get)',
  //         hospital: '罗湖医院(get)',
  //         department: '生殖科(get)',
  //         specialty: '不孕不育专科(get)',
  //         price: 24,
  //         avatar_url: 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoVVhlSqEIqslaDUamZ9dMZFH6qwDEY3xbm8vssViaB2yHjSBkhl30YZs1zUCGacUsPqaDAiaRVfXYg/132'
  //       }
  //     })
  //   }, 2500)
  // })
  return api.get(`/api/v1/mp/inquiries/doctors/${payload}`)
}



