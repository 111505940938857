<template>
  <div class="wait">
    <span class="text">{{data.text}}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    data:{
      type: Object,
      default: ()=>{}
    },
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style scoped>
.text {
  height: 24px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 24px;
  color: #808080;
  text-align: center;
}
.wait {
  text-align: center;
}
</style>

