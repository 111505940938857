<template>
  <div :class="['relation_chart-out',{'default-height': msg_loading}]" v-show="data.id">
    <div class="chart_data_name" v-if="!data.other">以下是<span>{{chart_data.name}}</span>风险报告</div>
    <msg-loading :msg_loading="msg_loading" />
    <div class="relation_chart-card" :class="data.other?'other':''" v-show="!msg_loading && chart_data">
      <div class="relation_chart-title">
        <div class="relation_chart-name"> {{data.other?'您存在':''}}{{ chart_data.name }}患病风险{{data.other?'因素':''}}</div>
        <div class="relation_chart-level">
          {{ chart_data.level }}
        </div>
      </div>
      <div class="relation_chart-ad" v-if="!data.other">
        <div class="relation_chart-left">
          <p>{{ chart_data.ad_title }}</p>
          <span> {{chart_data.ad_desc}} </span>
        </div>
        <div class="relation_chart-adBtn" @click="ad_fn">了解更多</div>
      </div>
      <div class="relation_chart-name" :class="data.other?'other':''">{{!data.other?'风险因素评估':'连线表明您存在风险因素'}}</div>
      <div class="chart" ref="myChart" :style="{height: auto_height}"></div>
      <div class="chart_text" :class="data.other?'other':''"> {{!data.other?'* 线条越粗关联性越强':'为了尽快排除患病风险请您尽快完成风险评估'}}</div>
      <div class="relation_chart-bottom">
        <div class="relation_chart-handle" :class="data.other?'other':''" @click="confirm_fn">{{!data.other?'我要处理':'立刻开始风险评估'}}</div>
        <div class="relation_chart-cancel" v-if="!data.other" @click="cancel_fn">
          我已了解风险 暂不处理
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format_decimal } from "../../../utils/format";
import { recommend_info } from "./service";

export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      timer: null,
      source: [],
      links: [],
      chart_data: {},
      msg_loading: true,
      auto_height: '200px',
      action_gauge_id: null,
      item_height: this.data.other? 40:25
    };
  },
  computed: {},
  watch: {
    chart_data() {
      this.init_echart()
    },
    msg_loading(v) {
      if(!v){
        this.$emit('loading_finished','关系图')
      }
    }
  },
  components: {},

  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.myChart);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.resize, false);
      this.myChart.clear();
      echarts.dispose(this.myChart);
      this.myChart = null;
    });
    this.get_data({
      id: this.data.action_result_id || this.$route.query.action_result_id,
      diagnosis_id: this.data.action_id || this.$route.query.action_id,
    })
    window.addEventListener("resize", this.resize, false);
  },
  methods: {
    format_decimal,
    resize() {
      // 防抖，有自定义图形组件时使用
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.myChart.resize();
        this.echarts();
      }, 50);
    },
    // 初始数据
    init_data() {
      this.source = [];
      this.links = [];
      this.chart_data.children.forEach((el) => {
        this.source.push(el);
      });
      this.source.push({
        name: this.chart_data.name,
      });

      this.source.forEach((el, idx) => {
        el.x = 0;
        el.y = this.item_height * idx;
        el.label = {
          show: true,
          position: "left",
        };
        if (idx === this.source.length - 1) {
          el.x = 250;
          el.y = 0;
          el.label = {
            show: true,
            position: "right",
          };
        }
        this.links.push({
          source: idx,
          target: this.source.length - 1,
          label: {
            show: false,
          },
          lineStyle: {
            width: el.weight * 10,
            curveness: -0.015 * idx,
          },
        })
      })
      if(this.links.length) {
        this.auto_height = this.item_height*(this.links.length - 1) + 'px'
      }
    },
    async get_data(id) {
      this.msg_loading = true;
      try {
        const { data } = await recommend_info(id)
        this.chart_data = !this.data.other ? {
          name: data.result,
          children: [...data.factors],
        }: {
          name: '多囊卵巢综合征',
          children: [
            {
              name: '高发期年龄',
              weight: 0.6
            },
            {
              name: '不孕史',
              weight: 0
            },
            {
              name: '超重、肥胖',
              weight: 0
            },
            {
              name: '女性',
              weight: 0.2
            },
            {
              name: '生育期',
              weight: 0.2
            },
          ]
        }
        this.init_echart();
      } finally {
        this.msg_loading = false;
      }
    },
    init_echart() {
      this.init_data()
      if(this.myChart){
        this.$nextTick(() => {
          this.myChart.resize();
          this.echarts();
        });
      }
    },
    echarts() {
      let option = {
        grid: {
          top: '5%',
          left: '3%',
          right: '3%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            type: "graph",
            // roam: true,
            left: 70,
            right: 80,
            symbolSize: 1,
            edgeSymbol: ["none", "none"],
            edgeSymbolSize: 10,
            itemStyle: {
              normal: {
                show: false,
              },
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#565656",
                  fontStyle: "normal",
                  fontSize: 12,
                },
              },
            },
            data: [...this.source],
            links: [...this.links],
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
          },
        ],
      }
      this.myChart.setOption(option);
    },
    ad_fn() {
      this.$emit("ad_click");
    },
    confirm_fn() {
      
      this.$emit("callback", {id: 11, event: 1});
    },
    cancel_fn() {
      this.$emit("callback", {id: 11, event: 2});
    },
  },
};
</script>

<style scoped>
.chart_data_name {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  padding: 14px;
  font-size: 14px;
  line-height: 18px;
  color: #565656;
  border-radius: 0px 20px 20px 20px;
  margin-bottom: 20px;
  margin-right: 32px;
  & span {
    color:rgba(24, 144, 255, 1);
  }
}
.relation_chart-out {
  width: 100%;
  &.default-height {
    min-height: 400px;
  }
}

.relation_chart-card {
  padding: 16px;
  color: #222222;
  font-size: 14px;
  line-height: 19px;
  border-radius: 10px;
  background: #fff;

  &.other {
    padding: 16px 0 16px 10px;
    background: transparent;
  }
}

.relation_chart-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
}

.relation_chart-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;

  &.other {
    font-size: 14px;
    font-weight: 400;
    margin-top: -20px;
    color: #565656;
  }
}

.relation_chart-level {
  font-size: 17px;
  color: tomato;
}

.relation_chart-ad {
  color: #6F5155;
  background: #FFEAEA;
  border-radius: 10px;
  padding: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.relation_chart-left {
  & p {
    font-weight: 500;
    font-size: 14px;
  }
}

.relation_chart-adBtn {
  background: #EE8595;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  color: #fff;
}

.relation_chart-bottom {
  padding-top: 12px;
  display: flex;
  align-items: center;
  border-top: solid 1px #eee;
}

.relation_chart-handle {
  height: 32px;
  line-height: 32px;
  width: 90px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  background: #1890ff;
  margin-right: 10px;

  &.other {
    width: 100%;
    margin-right:0;
  }
}

.relation_chart-cancel {
  color: #1890ff;
}

.chart {
  width: 100%;
  min-height: 60px;
  padding: 10px 0;
}

.chart_text {
  margin: 20px 0;
  text-align: center;
  font-size: 12px;
  color: orange;

  &.other {
    font-size: 14px;
    color: #565656;
    margin: 10px 0;
  }
}
</style>
