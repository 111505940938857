<template>
  <div>
    <MsgText
    :text="data.data&&(data.data.question || data.data.title)"/>
    <div class="finish">
      点击标签快速回答
      <ul :class="['select-li',{'no-select': data.data&&data.data.title&&!is_last}]">
        <li v-for="(i,index) in data.actions" @click="select_answer(i)" :key="index">{{i.text}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import MsgText from '../im-basic/MsgText'
export default {
  data() {
    return {
      tab_list: [],
      select_i: {},
    }
  },
  props: {
    is_last: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    MsgText
  },
  computed: {
  },
  created(){

  },
  methods: {
    select_answer (val) {
      console.log('select_answer')
      if(val.type === 1)  {
        this.$router.push({
          path: '/consultation',
          query: {
            from: 'online-chat'
          }
        })
        this.$emit('save_params',val)
      } else if(val.type === 2) {
        this.$emit('select_time')
        this.$emit('callback',val)
      } else if(val.text === '在线问诊') {
        this.$router.push({
          path: '/online-inquiry'
        })
      } else {
        this.$emit('callback',val)
      }
    }

  }
}
</script>
<style scoped>
.finish {
  padding-top: 16px;
  font-size: 12px;
  line-height: 18px;
  color: #565656;
  & .select-li {
    display: flex;
    &.no-select {
      pointer-events: none;
      opacity: 0.5;
    }
    & li {
      width: 103px;
      height: 32px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      margin-right: 10px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      font-weight: 500;
      color: #0088FF;
      margin-top: 16px;
    }
  }
}
</style>
