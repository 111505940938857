<template>
  <div class="g_page_box main_box">
    <g-loading :loading="loading" />
    <div
      class="patient-info"
      v-if="user_id !== '3:1'">
      <div class="img">
        <img
          width="100%"
          height="100%"
          v-if="profile.userProfile && profile.userProfile.avatar"
          :src="profile.userProfile.avatar"
        />
        <img
          width="100%"
          height="100%"
          v-else
          src="../../assets/img/online/doctor_default.png"
        />
      </div>
      <span class="name"
        >{{ profile.userProfile && profile.userProfile.nick
        }}{{
          profile.userProfile && profile.userProfile.nick === "健康助手"
            ? ""
            : "医生"
        }}</span
      >
      <div
        class="patient-record"
        @click="look_doctor_detail"
        v-if="profile.userProfile && profile.userProfile.nick !== '健康助手'"
      >
        介绍
      </div>
    </div>
    <div class="g_main_content chat-content" ref="scroll">
      <div class="history-text" v-if="!is_completed" @click="get_more_history">
        点击查看历史消息
      </div>
      <div v-for="(i, index) in message_list" :key="index" :ref="i.ID">
        <!-- 自定义消息 -->
        <template v-if="i.type === TIM.TYPES.MSG_CUSTOM">
          <!-- 带有ai提示风险提示 -->
           <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 3"
          >
            <MsgTextRobot
              v-if="i.payload.data&&i.payload.data.data"
              :data="{
                text: i.payload.data.data.text,
              }"
            />
          </div>
             <!-- 公共选择组件1.看病咨询 2.结束会话 3.记住时间 4.门诊挂号 -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 5"
          >
            <CommonSelect
              :is_last="message_list.length - 1 === index"
              :class="{ 'no-click': status === 2 }"
              @callback="callback"
              @save_params="save_params"
              @select_time="select_time"
              :data="i.payload.data"
            />
          </div>
           <!-- 公共操作对话框（自定义消息） -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 6"
          >
            <MsgCustomType3 :data="i.payload.data.data" />
          </div>
          <!-- 提示组件（自定义消息 -->
          <Type12 style="margin-bottom: 23px" v-if="i.payload.data.type === 12" :data="i.payload.data.data"/>
          <!-- 提示组件 -->
          <Type13 style="margin-bottom: 23px" v-if="i.payload.data.type === 13" :data="i.payload.data.data"/>
          <!-- 时间显示 -->
          <TimeShow style="margin-bottom: 5px" v-if="i.payload.data.type === 991" :data="i.payload.data.data"/>


          <!--风险提示组件 -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 7"
            style="padding-right: 18px"
          >
            <RelationChart
              @callback="callback"
              :data="i.payload.data.data"
            />
          </div>
          <!-- 在线咨询组件 -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 8"
            style="padding-right: 18px"
          >
            <InfoCard :data="i.payload.data.data" />
          </div>
          <!-- 报告组件 -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 9"
            style="padding-right: 0"
          >

            <ReportRard :data="i.payload.data.data" />
          </div>
           <!-- 检验检查提示组件 -->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 10"
            style="padding-right: 18px"
          >
            <RecommendCard
              @callback="callback"
              :data="i.payload.data.data"
            />
          </div>
           <!--复诊 开药-->
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.payload.data.type === 11"
            style="padding-right: 0"
          >
            <consultationCard
              @callback="callback"
              :data="i.payload.data.data"
            />
          </div>
        </template>
        <!-- 非自定义消息 -->
        <template v-else>
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.type === TIM.TYPES.MSG_TEXT"
          >
            <!--文字-->
            <MsgText
              :text="i.payload.text"
              :is_right="i.from === current_user"
            />
            <van-icon
              v-if="i.status === 'fail'"
              :class="{ 'no-click': status === 2 }"
              @click="re_set(i)"
              class="chat-warning"
              name="warning"
            />
          </div>
          <div
            :class="['chat-box1', { 'chat-box': i.from === current_user }]"
            v-if="i.type === TIM.TYPES.MSG_IMAGE"
          >
            <!-- 图片-->
            <MsgImage :url="i.payload.imageInfoArray[0].url" />
            <van-icon
              v-if="i.status === 'fail'"
              :class="{ 'no-click': status === 2 }"
              @click="re_set(i)"
              class="chat-warning"
              name="warning"
            />
          </div>
        </template>
      </div>
      <!-- 倒计时 -->
      <BackToIndex v-if="is_show" :time="20 * 1000" />
    </div>
    <div
      class="time"
      v-if="status === 1 && user_id !== '3:1'"
    >
      <van-icon
        class="time-icon"
        name="underway-o"
      />剩余问诊时间：<van-count-down
        :time="+left_seconds"
        format="HH 时 mm 分 ss 秒"
      />
    </div>
    <!-- 重新会话 -->
    <div
      :class="['chat-input1', 'g-default-height', { 'g-ios-height': is_ios }]"
      v-if="status === 2 && user_id !== '3:1'"
    >
      <RestartChat :online_doctor_id="online_doctor_id" />
    </div>
    <!-- 会话进行中 -->
    <div
      :class="['chat-input', 'g-default-height', { 'g-ios-height': is_ios }]"
      v-if="status !== 2 && user_id !== '3:1'"
    >
      <div class="chart-area">
        <!-- <img class="icon" width="28" height="28" src="../../assets/img/online/voice.png"/> -->
        <div class="text-input">
          <textarea
            rows="1"
            wrap="physical"
            ref="textarea"
            class="input"
            enterkeyhint="send"
            type="text"
            @keyup.13="tapToSearch"
            v-model="word"
          />
        </div>
        <!-- <img class="icon" width="28" height="28"  src="../../assets/img/online/expression.png"/> -->
        <img
          width="28"
          style="margin-left: 10px"
          @click.stop="look_more"
          height="28"
          src="../../assets/img/online/other.png"
        />
      </div>
      <ul class="other" ref="other" v-if="show">
        <li class="other-i">
          <van-uploader class="img" :after-read="after_read">
            <img width="28" src="../../assets/img/online/image.png" />
          </van-uploader>
          图片
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {get_id,to_sent_msg,open_msg } from "./service";
import { mapMutations, mapState } from "vuex";
import TIM from "tim-js-sdk";
import {
  get_onversation_list,
  get_history_message,
  get_conversation,
  user_conversation_id,
  set_message_read,
  resend_message,
  send_img_message,
  send_custom_message,
  send_text_message
} from "../../im/index";
import { arr_obj } from "../../utils/format";

// 公共组件
import MsgText from "../../components/im-basic/MsgText";
import MsgTextRobot from "../../components/im-basic/MsgTextRobot";
import MsgImage from "../../components/im-basic/MsgImage";
import CommonSelect from "../../components/im-basic/CommonSelect";
import MsgCustomType3 from "../../components/im-basic/MsgCustomType3";
import Type12 from "../../components/im-basic/Type12";
import Type13 from "../../components/im-basic/Type13";
import TimeShow from "../../components/im-basic/TimeShow";

import RestartChat from "../../components/im/RestartChat";
import RelationChart from "../../components/im-basic/relation_chart";
import ReportRard from "../../components/im-basic/report_card";
import RecommendCard from "../../components/im-basic/recommend_card";
import consultationCard from "../../components/im-basic/consultation_card";
import InfoCard from "../../components/im-basic/info_card";
import BackToIndex from "../../components/im-basic/BackToIndex";

export default {
  data() {
    return {
      word: "",
      show: false,
      TIM,
      loading: false,
      is_history: false,
      timer: null,
      last_id: "", //点击查看历史最后一个id
      is_show: false,
      online_doctor_id: "",
      timer1: null,
    };
  },
  components: {
    // 公共
    MsgText,
    MsgTextRobot,
    MsgImage,
    CommonSelect,
    MsgCustomType3,
    Type12,
    Type13,
    TimeShow,

    RestartChat,
    RelationChart,
    ReportRard,
    RecommendCard,
    consultationCard,
    InfoCard,
    BackToIndex,

  },
  computed: {
    ...mapState("im", {
      conversation_list: (state) => state.conversation_list,
      all_conversation_data: (state) => state.all_conversation_data,
      current_user: (state) => state.user_id,
    }),
    ...mapState("commonData", {
      is_ios: (state) => state.is_ios,
    }),
    ...mapState("illList", {
      user_ills: (state) => state.illList,
    }),
    ...mapState("online_chat", {
      data_obj: (state) => state.data_obj,
    }),
    user_id() {
      return this.$route.query.id;
    },
    conversation_id() {
      return user_conversation_id(this.user_id);
    },
    conversation_obj() {
      return arr_obj(this.conversation_list, { value: "conversationID" });
    },
    // 有无历史消息
    is_completed() {
      const data = this.all_conversation_data[this.conversation_id];
      if (data) return data.is_completed;
      return false;
    },
    // 消息列表
    message_list() {
      const data = this.all_conversation_data[this.conversation_id];
      if (data) return data.message_list;
      return [];
    },
    // 会话资料
    profile() {
      return this.conversation_obj[this.conversation_id] || {};
    },
    // 会话状态
    status() {
      return this.profile._state;
    },
    left_seconds() {
      let time = 0
      if(this.profile) {
        let now_time = new Date().getTime()
        let pass_time = now_time - this.profile._update_time
        time = this.profile._left_seconds - pass_time
      }
      return time
    }
  },
  watch: {
    profile() {
      if (this.profile.userProfile && this.profile.userProfile.nick) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          document.title =
            this.profile.userProfile.nick === "健康助手"
              ? this.profile.userProfile.nick
              : this.profile.userProfile.nick + "医生";
        }, 100);
      }
    },
    message_list(val) {
      console.log(val);
      this.set_message_read(this.conversation_id);
      this.$nextTick(() => {
        let scrollHeight = this.is_history
          ? this.$refs[this.last_id][0].offsetTop
          : this.$refs.scroll.scrollHeight;
        this.$refs.scroll.scrollTo(0, scrollHeight - 300);
        this.is_history = false;
      });
    },
    word() {
      this.getHeight();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.timer1) clearTimeout(vm.timer1);
      vm.timer1 = setTimeout(() => {
        let scrollHeight = vm.$refs.scroll.scrollHeight;
        vm.$refs.scroll.scrollTop = scrollHeight;
      }, 1000)

      if(from.path === "/consultation") {
        vm.callback(vm.data_obj,vm.user_ills)
        return
      }
      if (
        from.path === "/inspection-order" ||
        from.path === "/electronic-rescription" ||
        from.path === "/health-record-exam" ||
        from.path === "/health-record-lab"
      ) {
        return;
      }
      vm.clearState()
      vm.init();
      vm.set_message_read(vm.conversation_id);
    });
  },
  created() {
    this.search_id(this.$route.query.id)
  },
  mounted() {
    document.addEventListener("click", this.func, false);
  },
  destroyed() {
    document.removeEventListener("click", this.func, false);
  },
  methods: {
    ...mapMutations("online_chat", [
      'save_action',
      'clearState'
    ]),
    get_onversation_list,
    get_conversation,
    set_message_read,
    resend_message,
    getHeight() {
      let _this = this;
      this.$nextTick(() => {
        let textArea = _this.$refs.textarea;
        let scrollHeight = textArea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
        let height = textArea.offsetHeight; // 屏幕上显示的高度
        if (height <= scrollHeight) {
          textArea.style.height = "auto"; // 恢复默认值，这个作用就是根据内容自适应textarea高度
          textArea.style.height =
            (textArea.scrollHeight >= 80 ? 80 : textArea.scrollHeight) + "px"; // 拿到最新的高度改变textarea的高度
        }
      });
    },
    func(event) {
      let cDom = this.$refs.other;
      let tDom = event.target;
      if (cDom && !(cDom === tDom || cDom.contains(tDom))) {
        this.show = false;
      }
    },
    look_more() {
      this.show = true;
    },
    // 重新发送
    re_set(i) {
      this.resend_message(i);
    },
    tapToSearch() {
      if (!this.word) return;
      this.sed_messge(this.word);
      this.word = "";
    },
    // 发送消息
    sed_messge(val) {
      send_text_message(this.conversation_id,val)
      this.$nextTick(() => {
        let textArea = this.$refs.textarea
        textArea.style.height = '20px'
      })
    },
    look_doctor_detail() {
      this.$router.push({
        path: "/online-inquiry-detail",
        query: {
          id: this.online_doctor_id,
          hide_pay: 1,
        },
      });
    },
    async to_send_custom_message(val) {
      await send_custom_message(this.conversation_id, val);
    },
    // 倒计时
    select_time() {
      this.is_show = true;
    },
    // 跳转页面保存参数
    save_params(val) {
      this.save_action(val)
    },
    // 回调函数 触发发送消息
    async callback(val,val2) {
      if (this.status === 2) return;
      this.loading = true;
      try {
        let params = {
          action: JSON.stringify(val.action)
        }
        console.log(val2)
        if(val2) params.param = JSON.stringify(val2)
        await to_sent_msg(params)
      } finally {
        this.loading = false;
      }
    },
    async after_read(file) {
      if (this.status === 2) return;
      this.loading = true;
      try {
        // 发送图片
        await send_img_message(this.conversation_id, file.file);
      } finally {
        this.loading = false;
      }
    },
    async get_more_history() {
      this.last_id =
        this.all_conversation_data[this.conversation_id] &&
        this.all_conversation_data[this.conversation_id].next_req_message_id;
      this.loading = true;
      try {
        // 获取会话
        await get_history_message(this.conversation_id);
        this.is_history = true;
      } finally {
        this.loading = false;
      }
    },

    async init() {
      this.loading = true;
      try {
        // 获取会话
        await get_onversation_list();
        let history = this.$route.query.history === "false" ? false : true;
        await get_conversation(this.conversation_id, false, history);
      } finally {
        this.loading = false;
      }
    },
    async search_id(id) {
      const { data } = await get_id(id);
      this.online_doctor_id = data.online_doctor_id;
    },
    async to_open_msg() {
      await open_msg({msg_id: ''})
    },
  },
};
</script>
<style scoped>
.main_box {
  background: #f2f7f8;
}
.time {
  height: 36px;
  background: #fefced;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  line-height: 38px;
  color: #5f5f5f;
  & .time-icon {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
  }
}
.patient-info {
  height: 53px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  color: #5f5f5f;
  z-index: 999;
   box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
  & .img {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0);
    border-radius: 18px;
    margin-right: 10px;
    & img{
      border-radius: 50%;
    }
  }
  & .name {
    flex: 1;
    color: #0088ff;
  }
  & .patient-record {
    width: 60px;
    height: 28px;
    border: 1px solid #0088ff;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    color: #0088ff;
  }
}
.chat-content {
  padding: 18px 0;
}

.chat-box1 {
  padding: 0 18px;
  margin-bottom: 23px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  & .chat-warning {
    font-size: 18px;
    color: #f35f5f;
    padding: 0 10px;
  }
  &.chat-box {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 50px;
    padding-right: 18px;
  }
  & .no-click {
    pointer-events: none;
  }
  & > img {
    margin-left: 10px;
  }
}
.chat-input1 {
  background: #fff;
  padding-top: 10px;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border-top: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
}
.chat-input {
  padding-top: 10px;
  background: #f8f8f8;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  & .chart-area {
    display: flex;
    align-items: center;
    padding: 0 10px;
    & .icon {
      margin-right: 9px;
    }
    & .text-input {
      min-height: 32px;
      background: #ffffff;
      border-radius: 4px;
      flex: 1;
      margin-right: 9px;
      padding: 5px 5px;
      & .input {
        width: 100%;
        line-height: 20px;
        height: 20px;
      }
    }
  }
  & .other {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-top: 15px;
    & .other-i {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      line-height: 28px;
      color: #707070;
      width: 25%;
      & .img {
        width: 54px;
        height: 54px;
        background: #fff;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        & .file {
          width: 54px;
          height: 54px;
          position: absolute;
          opacity: 0;
        }
      }
    }
  }
}

.visits {
  font-size: 12px;
  color: #808080;
  text-align: center;
  width: 126px;
  height: 24px;
  line-height: 24px;
  background: #efefef;
  border-radius: 18px;
  margin: 23px auto 23px;
}
.history-text {
  font-size: 12px;
  color: #808080;
  text-align: center;
  padding-bottom: 12px;
}
</style>
