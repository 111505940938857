import api from '../../../utils/api'


export async function recommend_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         result: '结果',
  //         factors: [
  //           {
  //             name: 'test1',
  //             weight: 0.2,
  //           },
  //           {
  //             name: 'test2',
  //             weight: 0.25,
  //           },
  //           {
  //             name: 'test3',
  //             weight: 0.25,
  //           },
  //           {
  //             name: 'test4',
  //             weight: 0.3,
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/gauges/answers/${payload.id}/factor?diagnosis_id=${payload.diagnosis_id}`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/triage/recommend/${id}`, payload)
}





