<template>
<div style="width: 100%;" v-if="data">
  <MsgTextRobot style=" margin-bottom: 20px;" v-if="data.type === 3" :data="{text:'我是AI健康助手，医生已为您开了处方单，请及时完成支付。'}"/>
  <MsgTextRobot style=" margin-bottom: 20px;" v-if="data.type === 2" :data="{text: '我是AI健康助手，医生已为您开了检验检查单，请及时完成支付并预约。'}"/>
    <div class="comment" @click="jup_comment">
      <div class="comment-top"><span>{{data.title}}</span>点击查看<van-icon class="icon" name="arrow" /></div>
      <div class="comment-center">
        <img width="44" height="44" v-if="data.type===1" src="../../assets/img/online/comment.png"/>
        <img width="48" height="48" v-else-if="data.type===2" src="../../assets/img/online/prescription-history.png"/>
        <img width="48" height="48" v-else src="../../assets/img/online/history.png"/>
        <div>
          {{data.question}}
          <div>{{data.desc}}</div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import MsgTextRobot from '../im-basic/MsgTextRobot.vue'
export default {
  data() {
    return {

    }
  },
  components:{
    MsgTextRobot
  },
  props: {
    data:{
      type: Object,
      default: ()=>{}
    }
  },
  computed: {
  },
  methods: {
    jup_comment() {
      if(this.data.type === 1){
        this.$router.push({
          path: '/online-comment-rate',
          query: {
            session_id: this.data.id
          }
        })
      } else if(this.data.type === 2) {
        this.$router.push({
          path: '/inspection-order',
          query: {
            order_id: this.data,
            id: this.$route.query.id
          }
        })
      } else if (this.data.type === 3) {
        this.$router.push({
          path: '/electronic-rescription',
          query: {
            order_id: this.data.id,
            id: this.$route.query.id
          }
        })
      } else if (this.data.type === 4) {
        this.$router.push({
          path: '/inspection-order',
          query: {
            order_id: this.data,
            id: this.$route.query.id
          }
        })
      }else if (this.data.type === 5) {
        console.log(this.data.type)
      }else if (this.data.type === 6) {
        this.$router.push({path: '/web', query: {url: 'https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/SF1410927588060', title: '物流查询'}})
      }else if (this.data.type === 7) {
        this.$router.push({
          path: '/article',
          query: {
            ...this.query_info
          }
        })
      } else {
        this.$router.push({
          path: '/gauge-task',
          query: {
            id: this.data.id,
          }
        })
      }

    },
  }
}
</script>
<style scoped>
.comment {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  padding: 0px 0px 17px;
  & .comment-top {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 38px;
    padding: 0 16px;
    color: #565656;
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border-bottom: 1px solid #EBEDF0;;
      transform: scaleY(0.5);
    }
    & >span {
      flex: 1;
      font-size: 16px;
    }
    & .icon {
      margin-left: 2px;
    }
  },
  & .comment-center {
    display: flex;
    align-items: center;
    padding: 14px 16px 0;
    & >div {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #565656;
      padding-left: 13px;
      & div{
        font-size: 12px;
        font-weight: 400;;
        color:rgba(156, 156, 156, 1);
      }
    }
  }
}
</style>
